import { useMutation, useQuery } from '@tanstack/react-query'
import { UserSettingsFormData, UserSettingsResponse } from './UserSettings.types'
import { transformToUserSettingsUpdate } from './UserSettings.utils'

type UserData = {
  email_confirmed_status: 0 | 1
  success: boolean
}

export const fetchUserSettingsData = async (): Promise<UserSettingsResponse> => {
  const url = `/bff/user`

  const res = await fetch(url, {
    method: 'GET',
  })

  if (!res.ok) {
    throw new Error('Incorrect network response')
  }

  try {
    const result = await res.json()
    return result as UserSettingsResponse
  } catch (error) {
    throw new Error('Response was not json')
  }
}

export function useGetUserSettingsData() {
  return useQuery(['fetch-user-settings-data'], () => fetchUserSettingsData(), {
    keepPreviousData: true,
    retry: 0,
  })
}

export const updateUserSettings = async (payload: UserSettingsFormData): Promise<any> => {
  const url = `/bff/user/update`

  const normalizedPayload = transformToUserSettingsUpdate(payload)

  const formData = new FormData()
  formData.append('postArray', JSON.stringify(normalizedPayload.postArray))

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  })

  if (!res.ok) {
    throw new Error('Incorrect network response')
  }

  try {
    const response = await res.json()
    return response
  } catch (error) {
    throw new Error('Response was not json')
  }
}

export function useUpdateUserSettings() {
  const mutation = useMutation((payload: UserSettingsFormData) => updateUserSettings(payload))
  return mutation
}

export interface UserInfoRoot {
  success: boolean
  user_info: UserInfo
}

export interface UserInfo {
  user_id: number
  portal_id: number
  account_status: number
  email_address: string
  password_md5: string
  password_hash: string
  first_name: string
  last_name: string
  website_url: string
  company_name: string
  tax_id: string
  address: string
  city: string
  post_code: string
  country_code: string
  state: string
  subscription_id: number
  payment_frequency: string
  email_service_updates: number
  email_confirmation_key: string
  email_confirmed_status: number
  api_access_key: string
  signup_time_stamp: number
  payment_profile_id: string
  tos_accepted: number
  ip_address: string
  one_time_signin_key: string
  secret_key: string
  paylane_fraud_check_off: string
  user_origin: string
  user_origin_full: string
  column_last_updated: ColumnLastUpdated
  last_login_timestamp: number
  test_user: number
  default_webhook_url: string
  invoice_allowed: string
  invoice_payment_terms: string
  invoice_custom_email: string
  sales_person_id: string
  last_zendesk_ticket_id: any
  conversion_tracker_fired: string
  last_usage_reminder_ts_100: string
  last_usage_reminder_ts_90: string
  last_usage_reminder_ts_75: string
  overage_billing_enabled: number
  temp_fraud_suspension: string
  email_invoices: string
  usage_this_month: any
  override_info: boolean | any
  sales_person_timestamp: any
  monthly_invoicing_cron_last_period: string
  promotional_emails_consent: string
  stripe_customer_id: string
  stripe_eversign_migrated: boolean
  multiple_ips_allowed: any
  last_usage_reminder_cron: string
  firstpromoter_added: string
  firstpromoter_error: string
  firstpromoter_tid: string
  apl_master_account_id: string
  feature_flag_id: any
  subscription_plan_name: string
  current_billing_cycle: string
  monthly_price: number
  actual_subscription_id: string
  subscription_expiration: string
}

export interface ColumnLastUpdated {
  date: string
  timezone_type: number
  timezone: string
}


export async function fetchUserInfo(): Promise<UserInfoRoot> {
  const fetchUserDetails = await fetch('/bff/user/info')

  if (!fetchUserDetails.ok) {
    throw new Error('Failed to fetch user data')
  }

  const fetchUserDetailsJSON = await fetchUserDetails.json()

  return fetchUserDetailsJSON
}

export async function fetchUserEmailStatus(): Promise<UserData> {
  const fetchUserDetails = await fetch('/bff/user/me')

  if (!fetchUserDetails.ok) {
    throw new Error('Failed to fetch user data')
  }

  const fetchUserDetailsJSON = await fetchUserDetails.json()

  return fetchUserDetailsJSON
}

export function useUserInfo() {
  return useQuery(['fetch-user-info'], () => fetchUserInfo(), {
    keepPreviousData: true,
    retry: 0,
  })
}

export function useUserEmailStatus() {
  return useQuery(['fetch-user-data'], () => fetchUserEmailStatus(), {
    keepPreviousData: true,
    retry: 0,
  })
}

export const resendConfirmationEmail = async () => {
  const url = `/bff/user/resend_confirmation_email`

  const res = await fetch(url, {
    method: 'POST',
  })

  if (!res.ok) {
    throw new Error('Incorrect network response')
  }

  try {
    const response = await res.json()
    return response
  } catch (error) {
    throw new Error('Response was not json')
  }
}

export function useResendConfirmationEmail() {
  return useMutation({
    mutationFn: () => {
      return resendConfirmationEmail()
    },
  })
}
